<template>
  <div class="bonus-quickstart">
    <div class="bonus-quickstart__date">
      <span>Осталось времени</span>
      <p>{{ timeLeft() }}</p>
    </div>

    <bonus-quickstart-cards :end-date="bonusQuickstartEndDate" />
  </div>
</template>

<script>
// timer
import timer from "@/mixins/timer.mixins";
import BonusQuickstartCards from "./BonusQuickstartCards.vue";

export default {
  components: { BonusQuickstartCards },

  mixins: [timer],

  props: {
    bonusQuickstartEndDate: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.startTimer(this.bonusQuickstartEndDate);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.bonus-quickstart {
  &__date {
    margin-bottom: $space-l;

    span {
      @include body-1;
      color: $dark-fifth;
      margin-bottom: $space-xs;
    }

    p {
      @include body-1;
      color: $dark-primary;
    }
  }
}
</style>
