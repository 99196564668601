<template>
  <div class="bonus-quickstart-rewards-item">
    <template v-if="bonusQuickstart.is_complete">
      <div class="bonus-quickstart-rewards-item_is-complete">
        <h6 class="bonus-quickstart-rewards-item__title">Бонус получен!</h6>
        <div class="bonus-quickstart-rewards-item__amount">
          <p class="bonus-quickstart-rewards-item__amount_selected">
            {{ bonusQuickstart.amount }} RC
          </p>
        </div>

        <div v-if="showAlert" class="bonus-quickstart-rewards-item__alert">
          Если в течение действия ББС самый низкий тариф изменится, мы сделаем
          перерасчет
        </div>
      </div>
    </template>

    <template v-if="!bonusQuickstart.is_complete && compareDates(endDate)">
      <div
        class="bonus-quickstart-rewards-item_in-progress"
        :class="{ 'bonus-quickstart-rewards-item_first-item': isFirstItem }"
      >
        <h6 class="bonus-quickstart-rewards-item__title">
          Вознаграждение при выполнении следующего бонуса
        </h6>
        <div class="bonus-quickstart-rewards-item__amount">
          <p
            v-for="rewardAmount in rewardAmounts"
            :key="rewardAmount.id"
            :class="{
              'bonus-quickstart-rewards-item__amount_selected':
                bonusQuickstart.amount === rewardAmount.amount,
            }"
          >
            <template v-if="bonusQuickstart.amount === rewardAmount.amount">
              <span>{{ rewardAmount.amount }} RC</span>
            </template>

            <template v-else>
              <span
                v-tooltip.top-start="
                  'Вы получите ' +
                  rewardAmount.amount +
                  ' RC вознаграждения, если минимальный подключенный тариф будет ' +
                  defineTariff(rewardAmount.amount)
                "
              >
                {{ rewardAmount.amount }} RC
              </span>
            </template>
          </p>
        </div>
      </div>
    </template>

    <template v-if="!bonusQuickstart.is_complete && !compareDates(endDate)">
      <div
        class="bonus-quickstart-rewards-item_time-over"
        :class="{ 'bonus-quickstart-rewards-item_first-item': isFirstItem }"
      >
        <h6 class="bonus-quickstart-rewards-item__title">
          Время получения бонуса истекло
        </h6>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    bonusQuickstart: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
    },

    endDate: {
      type: String,
    },
  },

  data() {
    return {
      rewardAmounts: [
        {
          id: 1,
          amount: 15000,
        },
        {
          id: 2,
          amount: 70000,
        },
        {
          id: 3,
          amount: 150000,
        },
      ],
    };
  },

  computed: {
    isFirstItem() {
      return this.index === 1;
    },

    showAlert() {
      return (
        this.bonusQuickstart.is_complete && this.compareDates(this.endDate)
      );
    },
  },

  methods: {
    defineTariff(amount) {
      switch (amount) {
        case 15000:
          return "Бизнес";
        case 70000:
          return "Премиум";
        case 150000:
          return "Франшиза";
      }
    },

    compareDates(endDate) {
      let now = new Date().getTime();
      let diff = new Date(endDate) - now;

      return diff > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-quickstart-rewards-item {
  max-width: 280px;

  &_is-complete {
    height: 148px;
    margin-bottom: $space-xl;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 576px) {
      height: auto;
      margin-bottom: $space-s;
    }
  }

  &_in-progress {
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 576px) {
      height: auto;
      margin-bottom: $space-s;
    }
  }

  &_time-over {
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 576px) {
      height: auto;
      margin-bottom: $space-s;
    }
  }

  &_first-item {
    height: 148px !important;

    @media (max-width: 576px) {
      height: auto !important;
    }
  }

  &__title {
    @include text-2;
    color: $dark-primary;
    margin-bottom: $space-xs;
  }

  &__amount {
    display: flex;

    p {
      @include text-2-bold;
      color: $light-fifth;

      &:not(:last-child) {
        margin-right: $space-s;
      }
    }

    &_selected {
      color: $dark-primary !important;
    }
  }

  &__alert {
    background-color: $gold-main;
    border-radius: $space-s;
    padding: $space-m;
    @include text-2;
    color: $dark-primary;
    max-width: 280px;
    margin-top: $space-s;
  }
}
</style>
