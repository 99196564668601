<script>
export default {
  props: {
    bonusName: {
      type: String,
      required: true,
    },

    bonusStatus: {
      type: Number,
      default: 0,
    },

    showBonusStatus: {
      type: Boolean,
      default: false,
    },

    bonusShow: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    openBonus(bonusName) {
      this.$emit("openBonus", bonusName);
    },
  },
};
</script>

<template>
  <div
    class="bonus-title"
    :class="{ 'bonus-title__opened': bonusShow }"
    @click="openBonus(bonusName)"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 2L11 8L5 14" stroke="#969696" />
    </svg>

    <p>
      {{ bonusName }}
      <span v-if="showBonusStatus">Выполнен {{ bonusStatus }} раз</span>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.bonus-title {
  display: flex;
  align-items: baseline;
  cursor: pointer;

  &__opened {
    margin-bottom: $space-xl;

    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    margin-right: $space-m;
    transition: transform 0.2s ease;
  }

  p {
    color: $dark-primary;
    margin-bottom: $space-m;
    @include title-3;

    span {
      @include text-1;
      color: $dark-fifth;
      margin-left: $space-m;

      @media (max-width: 768px) {
        margin-left: 0;
        margin-top: $space-s;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
