<template>
  <div class="bonus-quickstart-cards">
    <div class="bonus-quickstart-cards__wrapper">
      <div class="bonus-quickstart-cards__list">
        <bonus-quickstart-cards-item
          v-for="(bonusQuickstartItem, index) in getBonusQuickstartCompleted"
          :key="index"
          :bonus-quickstart="bonusQuickstartItem"
          :index="index + 1"
          :count-items="getBonusQuickstartCompleted.length"
        />
      </div>

      <div class="bonus-quickstart-cards__rewards">
        <bonus-quickstart-rewards-item
          v-for="(bonusQuickstartItem, index) in getBonusQuickstartCompleted"
          :key="index"
          :bonus-quickstart="bonusQuickstartItem"
          :index="index + 1"
          :end-date="endDate"
        />
      </div>
    </div>

    <div class="bonus-quickstart-cards__wrapper">
      <div class="bonus-quickstart-cards__list">
        <bonus-quickstart-cards-item
          v-for="(bonusQuickstartItem, index) in getBonusQuickstartInProgress"
          :key="index"
          :bonus-quickstart="bonusQuickstartItem"
          :index="index + 1"
          :count-items="getBonusQuickstartInProgress.length"
        />
      </div>

      <div class="bonus-quickstart-cards__rewards">
        <bonus-quickstart-rewards-item
          v-for="(bonusQuickstartItem, index) in getBonusQuickstartInProgress"
          :key="index"
          :bonus-quickstart="bonusQuickstartItem"
          :index="index + 1"
          :end-date="endDate"
        />
      </div>
    </div>

    <!-- <div class="bonus-quickstart-cards__rewards">
      <bonus-quickstart-rewards-item
        v-for="(bonusQuickstartItem, index) in getBonusQuickstartCompleted"
        :key="index"
        :bonus-quickstart="bonusQuickstartItem"
        :index="index + 1"
        :end-date="endDate"
      />

      <bonus-quickstart-rewards-item
        v-for="(bonusQuickstartItem, index) in getBonusQuickstartInProgress"
        :key="index"
        :bonus-quickstart="bonusQuickstartItem"
        :index="index + 1"
        :end-date="endDate"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BonusQuickstartCardsItem from "@/components/bonuses/BonusQuickstartCardsItem.vue";
import BonusQuickstartRewardsItem from "@/components/bonuses/BonusQuickstartRewardsItem.vue";

export default {
  components: {
    BonusQuickstartCardsItem,
    BonusQuickstartRewardsItem,
  },

  props: {
    endDate: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      // getBonusQuickstart: "bonuses/getBonusQuickstart",
      getBonusQuickstartCompleted: "bonuses/getBonusQuickstartCompleted",
      getBonusQuickstartInProgress: "bonuses/getBonusQuickstartInProgress",
    }),
  },
};
</script>

<style lang="scss" scoped>
.bonus-quickstart-cards {
  &__list {
    margin-right: $space-l;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 270px minmax(200px, 250px);
    column-gap: $space-l;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
</style>
