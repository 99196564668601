import { compareAsc } from "date-fns";

/*
 * Compare the two dates and return 1 if the first date is after the second,
 * -1 if the first date is before the second or 0 if dates are equal.
 */

/**
 * @param dateFirst - new Date() format
 * @param dateSecond - new Date() format
 * @returns {number}
 */

export default function compareDates(dateFirst, dateSecond) {
  return compareAsc(dateFirst, dateSecond);
}
