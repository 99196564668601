var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bonus-quickstart-cards-item",class:{
    'bonus-quickstart-cards-item_is-complete':
      _vm.bonusQuickstart.is_complete || (_vm.isFirstItem && _vm.countItems === 1),
    'bonus-quickstart-cards-item_first-item': _vm.isFirstItem,
    'bonus-quickstart-cards-item_central-item': _vm.isCentralItem,
    'bonus-quickstart-cards-item_last-item': _vm.islastItem,
  }},[_c('bonus-quickstart-cards-item-leg',{attrs:{"position":"left","bonus-quickstart":_vm.bonusQuickstart,"show-title":_vm.showTitle}}),_c('bonus-quickstart-cards-item-leg',{attrs:{"position":"right","bonus-quickstart":_vm.bonusQuickstart,"show-title":_vm.showTitle}}),_c('div',{staticClass:"bonus-quickstart-cards-item__border"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }