<template>
  <div class="bonus-leader-share">
    <p class="bonus-leader-share__title">Квалификации</p>

    <ul class="bonus-leader-share__tariffs">
      <li v-for="(qualification, index) in qualifications" :key="index">
        {{ formatQualification(qualification) }}
      </li>
    </ul>
  </div>
</template>

<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";

export default {
  props: {
    qualifications: {
      type: Array,
      required: true,
    },
  },

  methods: {
    formatQualification(qualificationCode) {
      return formatQualification(qualificationCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-leader-share {
  padding: $space-m;
  border-radius: $space-s;
  background-color: $light-second;
  width: fit-content;

  &__title {
    @include text-2;
    color: $dark-fifth;
    margin-bottom: $space-s;
  }

  &__tariffs {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @include body-1;
      color: $dark-primary;

      &:not(:last-child) {
        margin-bottom: $space-s;
      }
    }
  }
}
</style>
