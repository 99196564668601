<template>
  <div class="bonus-leader-duration">
    <p class="bonus-leader-duration__title">Время действия</p>

    <div class="bonus-leader-duration__date date">
      <p class="date__duration">{{ formattedDistance }}</p>
      <p class="date__expired-at">до {{ formattedDate }}</p>
    </div>
  </div>
</template>

<script>
import { compareDesc, formatDistanceStrict, intlFormat } from "date-fns";
import ruLocale from "date-fns/locale/ru";

export default {
  props: {
    expiredAt: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return intlFormat(
        new Date(this.expiredAt),
        {
          year: "numeric",
          month: "long",
        },
        {
          locale: "ru-RU",
        }
      );
    },

    formattedDistance() {
      let distance;

      if (compareDesc(new Date(), new Date(this.expiredAt))) {
        distance = formatDistanceStrict(new Date(this.expiredAt), new Date(), {
          locale: ruLocale,
        });
      } else {
        distance = "0 месяцев";
      }

      return distance;
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-leader-duration {
  border-radius: $space-s;
  background-color: $light-second;
  padding: $space-m;
  width: fit-content;
  margin-left: $space-m;

  &__title {
    @include text-2;
    color: $dark-fifth;
    margin-bottom: $space-s;
  }

  .date {
    &__duration {
      @include body-1-bold;
      color: $dark-second;
    }

    &__expired-at {
      @include body-1;
      color: $dark-second;
    }
  }
}
</style>
