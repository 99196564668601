<template>
  <div class="tariff-discount">
    <img
      class="tariff-discount__icon"
      src="../../assets/icons/tariffs/vip_gold_d_dark.svg"
      alt=""
    />

    <div class="tariff-discount__top">
      <h6 v-if="tariff.until" class="tariff-discount__title">
        Акция «Франшиза Голд» со скидкой
      </h6>
      <h6 v-else class="tariff-discount__title">Выгодное предложение</h6>
      <div v-if="tariff.until" class="tariff-discount__time">
        <span>Осталось времени</span>
        <p>{{ timeLeft() }}</p>
      </div>
    </div>

    <div class="tariff-discount__bottom">
      <div class="tariff-discount__price price">
        <div>
          <h2 class="price__new">{{ tariff.user_price }} RC</h2>
          <p class="price__old">вместо {{ tariff.price }} RC</p>
        </div>

        <span class="price__discount">{{ discount }}</span>
      </div>

      <div class="tariff-discount__button">
        <tooltip-up-balance
          v-if="balanceError"
          title="Не хватает баллов"
          description="Пополните баланс через банковскую карту"
          :tooltip-position="'bottom'"
          @close="balanceError = false"
        />

        <main-button
          color="gold"
          :title="'Апгрейд'"
          @click="redirectToTariff"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import timer from "@/mixins/timer.mixins";
import MainButton from "@/components/helpers/MainButton";
import TooltipUpBalance from "@/components/tooltips/TooltipUpBalance";

export default {
  components: {
    MainButton,
    TooltipUpBalance,
  },

  mixins: [timer],

  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      balanceError: false,
    };
  },

  computed: {
    ...mapGetters({
      getUserDistributorAccount: "auth/getUserDistributorAccount",
    }),

    discount() {
      return this.tariff.until ? "30%" : "27%";
      // return (
      //   "-" +
      //   Math.round(
      //     ((this.tariff.price - this.tariff.user_price) / this.tariff.price) *
      //       100
      //   ) +
      //   "%"
      // );
    },
  },

  methods: {
    redirectToTariff() {
      if (
        this.tariff.user_price < this.getUserDistributorAccount.summary_active
      ) {
        this.$router.push({
          name: "TariffBuy",
          params: { tariffId: this.tariff.id },
        });
      } else {
        this.balanceError = true;
      }
    },
  },

  mounted() {
    this.startTimer(this.tariff.until);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.tariff-discount {
  max-width: 644px;
  background-color: $light-second;
  border-radius: $space-s;
  padding: $space-xl;
  background-image: url("../../assets/sale.png");
  background-position: top right;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 576px) {
    padding: $space-l;
  }

  &__icon {
    position: absolute;
    right: 32px;
    bottom: 32px;

    @media (max-width: 576px) {
      display: none;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-xl;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 56px;
    }
  }

  &__title {
    @include body-1;
    color: $dark-primary;

    @media (max-width: 576px) {
      margin-bottom: $space-m;
    }
  }

  &__time {
    text-align: end;

    @media (max-width: 576px) {
      text-align: start;
    }

    span {
      @include body-1;
      color: $dark-fifth;
    }

    p {
      @include body-1;
      color: $dark-primary;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }

  &__price {
    display: flex;
    align-items: center;
    margin-bottom: $space-xl;
  }

  .price {
    &__old {
      @include text-2;
      color: $dark-fifth;
      text-align: start;
    }

    &__new {
      @include title-2;
      color: $dark-primary;
      text-align: start;
    }

    &__discount {
      margin-left: $space-xl;
      height: 60px;
      width: 60px;
      background-color: $dark-primary;
      border-radius: 50%;
      color: $light-primary;
      @include body-1-bold;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 576px) {
        margin-left: $space-m;
      }
    }
  }

  &__button {
    position: relative;

    > .main-button {
      max-width: 300px;
    }
  }
}
</style>
