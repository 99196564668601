var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bonus-quickstart-rewards-item"},[(_vm.bonusQuickstart.is_complete)?[_c('div',{staticClass:"bonus-quickstart-rewards-item_is-complete"},[_c('h6',{staticClass:"bonus-quickstart-rewards-item__title"},[_vm._v("Бонус получен!")]),_c('div',{staticClass:"bonus-quickstart-rewards-item__amount"},[_c('p',{staticClass:"bonus-quickstart-rewards-item__amount_selected"},[_vm._v(" "+_vm._s(_vm.bonusQuickstart.amount)+" RC ")])]),(_vm.showAlert)?_c('div',{staticClass:"bonus-quickstart-rewards-item__alert"},[_vm._v(" Если в течение действия ББС самый низкий тариф изменится, мы сделаем перерасчет ")]):_vm._e()])]:_vm._e(),(!_vm.bonusQuickstart.is_complete && _vm.compareDates(_vm.endDate))?[_c('div',{staticClass:"bonus-quickstart-rewards-item_in-progress",class:{ 'bonus-quickstart-rewards-item_first-item': _vm.isFirstItem }},[_c('h6',{staticClass:"bonus-quickstart-rewards-item__title"},[_vm._v(" Вознаграждение при выполнении следующего бонуса ")]),_c('div',{staticClass:"bonus-quickstart-rewards-item__amount"},_vm._l((_vm.rewardAmounts),function(rewardAmount){return _c('p',{key:rewardAmount.id,class:{
            'bonus-quickstart-rewards-item__amount_selected':
              _vm.bonusQuickstart.amount === rewardAmount.amount,
          }},[(_vm.bonusQuickstart.amount === rewardAmount.amount)?[_c('span',[_vm._v(_vm._s(rewardAmount.amount)+" RC")])]:[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(
                'Вы получите ' +
                rewardAmount.amount +
                ' RC вознаграждения, если минимальный подключенный тариф будет ' +
                _vm.defineTariff(rewardAmount.amount)
              ),expression:"\n                'Вы получите ' +\n                rewardAmount.amount +\n                ' RC вознаграждения, если минимальный подключенный тариф будет ' +\n                defineTariff(rewardAmount.amount)\n              ",modifiers:{"top-start":true}}]},[_vm._v(" "+_vm._s(rewardAmount.amount)+" RC ")])]],2)}),0)])]:_vm._e(),(!_vm.bonusQuickstart.is_complete && !_vm.compareDates(_vm.endDate))?[_c('div',{staticClass:"bonus-quickstart-rewards-item_time-over",class:{ 'bonus-quickstart-rewards-item_first-item': _vm.isFirstItem }},[_c('h6',{staticClass:"bonus-quickstart-rewards-item__title"},[_vm._v(" Время получения бонуса истекло ")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }