<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";
import BonusLeaderShare from "@/components/bonuses/BonusLeaderShare";
import QualificationPreview from "@/components/qualifications/QualificationPreview";
import BonusLeaderDuration from "@/components/bonuses/BonusLeaderDuration";

export default {
  components: {
    BonusLeaderDuration,
    QualificationPreview,
    BonusLeaderShare,
  },

  props: {
    bonusLeader: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedQualification() {
      return this.bonusLeader.current_qualification
        ? {
            code: this.bonusLeader.current_qualification,
            name: formatQualification(this.bonusLeader.current_qualification),
          }
        : { code: "without_qualification", name: "У вас нет квалификации" };
    },
  },
};
</script>

<template>
  <div class="bonus-leader">
    <div class="bonus-leader__status">
      <div class="bonus-leader__qualification">
        <qualification-preview
          :qualification="formattedQualification"
          :selected="true"
          :title="true"
          :disabled="!bonusLeader.has_bonus"
        />
      </div>

      <p>
        При закрытии квалификации от Лидер продаж предполагается лидерский бонус
        <br />
        (1% от товарооборота компании на полгода)
      </p>
    </div>

    <template v-if="bonusLeader.has_bonus">
      <p class="bonus-leader__subtitle">Вы получаете бонус</p>

      <div class="bonus-leader__wrapper">
        <bonus-leader-share :qualifications="bonusLeader.previous" />

        <bonus-leader-duration :expired-at="bonusLeader.expired_at" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.bonus-leader {
  &__status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $space-l;

    p {
      @include text-2;
      color: $dark-third;
      max-width: 328px;
    }
  }

  &__qualification {
    width: 356px;
    margin-right: $space-m;
    margin-bottom: $space-s;
  }

  &__subtitle {
    @include text-2;
    color: $dark-primary;
    margin-bottom: $space-m;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }
}
</style>
