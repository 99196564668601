<script>
import { mapState, mapActions } from "vuex";
import compareDates from "@/package/helpers/compare-dates.helpers";
import AccountSelector from "@/components/AccountSelector";
import TariffDiscount from "@/components/tariff/TariffDiscount";
import BonusTitle from "@/components/bonuses/BonusTitle.vue";
// import BonusFounder from "@/components/bonuses/BonusFounder";
import BonusLeader from "@/components/bonuses/BonusLeader";
import BonusQuickstart from "@/components/bonuses/BonusQuickstart.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "Bonuses",

  components: {
    BonusLeader,
    // BonusFounder,
    BonusTitle,
    AccountSelector,
    TariffDiscount,
    BonusQuickstart,
    MainLoader,
  },

  data() {
    return {
      bonusFounderShow: false,
      bonusLeaderShow: false,
      bonusQuickstartShow: false,
      tariffDiscountShow: false,

      loadingBonuses: false,
    };
  },

  watch: {
    selectedBinaryAccount(newValue) {
      this.loadingBonuses = true;

      Promise.all([
        this.loadBonusQuickstart({ binaryAccount: newValue.id }),
        this.loadBonusLeader({ binaryAccount: newValue.id }),
      ]).finally(() => {
        this.loadingBonuses = false;
      });
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      // bonusFounder: (state) => state.bonuses.bonusFounder,
      bonusQuickstart: (state) => state.bonuses.bonusQuickstart,
      bonusLeader: (state) => state.bonuses.bonusLeader,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      tariff: (state) => state.tariff.tariff,
    }),
  },

  methods: {
    ...mapActions({
      // loadBonusFounder: "bonuses/loadBonusFounder",
      loadBonusQuickstart: "bonuses/loadBonusQuickstart",
      loadBonusLeader: "bonuses/loadBonusLeader",
      loadTariff: "tariff/loadTariff",
    }),

    openBonus(bonusName) {
      switch (bonusName) {
        case "Бонус основателя":
          this.bonusFounderShow = !this.bonusFounderShow;
          break;
        case "Лидерский бонус":
          this.bonusLeaderShow = !this.bonusLeaderShow;
          break;
        case "Бонус быстрого старта":
          this.bonusQuickstartShow = !this.bonusQuickstartShow;
          break;
      }
    },

    compareDates() {
      return compareDates(new Date(this.tariff.until), new Date()) === 1;
    },
  },

  mounted() {
    if (this.selectedBinaryAccount) {
      this.loadingBonuses = true;

      Promise.all([
        /**
         * ! бонус основателя отключен на неопределенный срок
         */
        // this.loadBonusFounder(),
        this.loadBonusQuickstart({
          binaryAccount: this.selectedBinaryAccount.id,
        }),
        this.loadBonusLeader({ binaryAccount: this.selectedBinaryAccount.id }),
        this.loadTariff(7),
      ]).finally(() => {
        this.loadingBonuses = false;
      });
    } else {
      this.$router.push({ name: "Dashboard" });
    }
  },
};
</script>

<template>
  <div class="bonuses">
    <div v-if="selectedBinaryAccount" class="account-selector-wrapper">
      <account-selector />
    </div>

    <div class="bonuses__content">
      <template v-if="loadingBonuses">
        <main-loader />
      </template>

      <template v-else>
        <tariff-discount
          v-if="tariff && user.tariff.code === 'vip_d' && tariff.until"
          class="bonuses__discount"
          :tariff="tariff"
        />

        <div v-if="bonusQuickstart" class="bonuses__wrapper">
          <bonus-title
            bonus-name="Бонус быстрого старта"
            :bonus-show="bonusQuickstartShow"
            @openBonus="openBonus"
          />

          <bonus-quickstart
            v-if="bonusQuickstartShow"
            :bonus-quickstart-end-date="bonusQuickstart.finished_at"
          />
        </div>

        <!-- ! бонус основателя скрыт на неопределнный срок -->
        <!-- <div v-if="bonusFounder" class="bonuses__wrapper">
          <bonus-title
            bonus-name="Бонус основателя"
            :bonus-show="bonusFounderShow"
            :bonus-status="bonusFounder.total"
            :show-bonus-status="true"
            @openBonus="openBonus"
          />

          <bonus-founder
            v-if="bonusFounderShow"
            :bonus-founder-list="bonusFounder.months"
          />
        </div> -->

        <div v-if="bonusLeader" class="bonuses__wrapper">
          <bonus-title
            bonus-name="Лидерский бонус"
            :bonus-show="bonusLeaderShow"
            @openBonus="openBonus"
          />

          <bonus-leader v-if="bonusLeaderShow" :bonus-leader="bonusLeader" />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bonuses {
  &__content {
    background-color: $background-white;
    padding: $space-xxl $space-xxl;

    @media (min-width: 426px) and (max-width: 576px) {
      padding: $space-xxl $space-xl;
    }

    @media (max-width: 425px) {
      padding: $space-xxl $space-l;
    }

    @media (max-width: 375px) {
      padding: $space-xxl $space-m;
    }
  }

  &__discount {
    margin-bottom: $space-xxl;
  }

  &__wrapper {
    &:not(:last-child) {
      margin-bottom: $space-xxl;
    }
  }
}
</style>
