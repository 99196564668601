<template>
  <div class="bonus-quickstart-list-item-leg">
    <h5 v-if="showTitle" class="bonus-quickstart-list-item-leg__title">
      Команда {{ formattedPosition }}
    </h5>

    <div
      v-if="bonusQuickstart[position][0]"
      class="bonus-quickstart-list-item-leg__tariff"
    >
      <img :src="formatImgSource(bonusQuickstart[position][0])" alt="" />
      <p>{{ formatTariffName(bonusQuickstart[position][0]) }}</p>
    </div>
    <div
      v-else
      class="bonus-quickstart-list-item-leg__tariff bonus-quickstart-list-item-leg__tariff_empty"
    >
      <p>Пусто</p>
    </div>

    <div
      v-if="bonusQuickstart[position][1]"
      class="bonus-quickstart-list-item-leg__tariff"
    >
      <img :src="formatImgSource(bonusQuickstart[position][1])" alt="" />
      <p>{{ formatTariffName(bonusQuickstart[position][1]) }}</p>
    </div>
    <div
      v-else
      class="bonus-quickstart-list-item-leg__tariff bonus-quickstart-list-item-leg__tariff_empty"
    >
      <p>Пусто</p>
    </div>
  </div>
</template>

<script>
import formatTariff from "@/package/helpers/format-tariff.helpers";

export default {
  props: {
    position: {
      type: String,
      required: true,
    },

    bonusQuickstart: {
      type: Object,
      required: true,
    },

    showTitle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedPosition() {
      return this.position === "left" ? "L" : "R";
    },
  },

  methods: {
    formatTariffName(tariff) {
      return formatTariff(tariff);
    },

    formatImgSource(tariff) {
      return require(`../../assets/icons/tariffs/${tariff}_dark.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-quickstart-list-item-leg {
  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-m;
  }

  &__tariff {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }

    img {
      margin-right: $space-s;
      width: 16px;
    }

    p {
      @include body-1;
      color: $dark-primary;
    }

    &_empty {
      p {
        color: $light-fifth;
      }
    }
  }
}
</style>
