<template>
  <div
    class="bonus-quickstart-cards-item"
    :class="{
      'bonus-quickstart-cards-item_is-complete':
        bonusQuickstart.is_complete || (isFirstItem && countItems === 1),
      'bonus-quickstart-cards-item_first-item': isFirstItem,
      'bonus-quickstart-cards-item_central-item': isCentralItem,
      'bonus-quickstart-cards-item_last-item': islastItem,
    }"
  >
    <bonus-quickstart-cards-item-leg
      position="left"
      :bonus-quickstart="bonusQuickstart"
      :show-title="showTitle"
    />

    <bonus-quickstart-cards-item-leg
      position="right"
      :bonus-quickstart="bonusQuickstart"
      :show-title="showTitle"
    />

    <div class="bonus-quickstart-cards-item__border"></div>
  </div>
</template>

<script>
import BonusQuickstartCardsItemLeg from "./BonusQuickstartCardsItemLeg.vue";

export default {
  components: { BonusQuickstartCardsItemLeg },

  props: {
    bonusQuickstart: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
    },

    countItems: {
      type: Number,
    },
  },

  computed: {
    showTitle() {
      return this.index === 1 || this.bonusQuickstart.is_complete;
    },

    isFirstItem() {
      return (
        this.index === 1 &&
        this.countItems > 1 &&
        !this.bonusQuickstart.is_complete
      );
    },

    isCentralItem() {
      return (
        this.index !== 1 &&
        this.index !== this.countItems &&
        !this.bonusQuickstart.is_complete
      );
    },

    islastItem() {
      return (
        this.index === this.countItems &&
        this.countItems > 1 &&
        !this.bonusQuickstart.is_complete
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-quickstart-cards-item {
  display: grid;
  grid-template-columns: 102px 102px;
  column-gap: 20px;
  padding: 24px;
  background-color: $light-second;
  width: 270px;
  border-radius: $space-s;
  position: relative;

  &_is-complete {
    margin-bottom: $space-xl;
  }

  &_first-item {
    border-radius: $space-s $space-s 0 0;

    .bonus-quickstart-cards-item__border {
      position: absolute;
      bottom: 0;
      left: 24px;
      width: 217px;
      height: 1px;
      background-color: $light-fourth;
    }
  }

  &_last-item {
    border-radius: 0 0 $space-s $space-s;
  }
}
</style>
